<template lang="pug">
footer.footer
    .footer-container
        span#email.email
        .icons
            transition(name="quick-fade" appear mode="out-in")
                SocialIcons(v-if="$route.name != 'home'")
                a.icon-link(v-else :href="this.$globals.socials.substack" target="_blank", rel="noopener noreferrer") {{ $t("globals.newsletter") }}
                    img(v-svg-inline src="../assets/vector/open.svg" alt="" aria-hidden="true")
        small BeatrixGuitar {{ new Date().getFullYear() }}
    CookieBar
</template>

<script>
import SocialIcons from '@/components/SocialIcons'
import CookieBar from '@/components/CookieBar'

export default {
    name: 'Footer',
    components: {
        SocialIcons,
        CookieBar
    },
    mounted() { this.emailAddress() },
    methods: {
        emailAddress() {
        function obfuscateEmail(user, domain) {
            return user + '&#64;' + domain; // '&#64;' is the HTML entity for '@'
        }

        // Replace 'user' and 'domain' with your actual email parts
        var obfuscatedEmail = obfuscateEmail('info', 'beatrixguitar.com');

        // Set the obfuscated email to the span
        document.getElementById('email').innerHTML = '<a href="mailto:' + obfuscatedEmail + '">' + obfuscatedEmail + '</a>';
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/scss/_utilities';

.footer {
    position: relative;
    background: $contentBg-green;
    box-shadow: inset 0px 1px 0px 0px $outline;
    transition: $quick-transition;
    transition-delay: $quick-transition;
    z-index: $layer3;

    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 64px;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, #00000080 100%);
    }

    @include at(laptop) {
        width: 100%;
        position: fixed;
        bottom: 0;
    }
}

.beatrixguitar--home .footer {
    box-shadow: none;
    background: transparent;
}

.footer-container {
    box-sizing: border-box;
    padding: 16px;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    min-height: 64px;
    z-index: 1;
    position: relative;
    padding: $contentPaddingMobile;
    gap: 16px;
    text-align: left;

    @include at(laptop) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 8px $contentPaddingLaptop;
        max-width: $maxContentWidthDesktop;
        gap: 0;
    }

    @include at(laptopLarge) {
        padding: 8px $contentPaddingLaptop;
    }

    @include at(desktop) {
        padding: 8px $contentPaddingLaptop;
        max-width: $maxContentWidthDesktop;
    }
}

.email a {
    order: 1;
    font-size: 18px;

    @include at(laptop) {
        order: 0;
        text-align: left;
    }
}

small {
    order: 2;
    text-align: right;
}

.icon-link {
    justify-content: center;
    width: 100%;
}
</style>